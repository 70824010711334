import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutR from '@/views/AboutR'
import PlatformR from '@/views/PlatformR'
import TermAndBlockchain from '@/views/TermAndBlockchain'
import TermR from '@/views/TermR'

Vue.use(VueRouter)
// 後來乾脆全部統一是可以接收product參數的url，不然有時候頁面轉換上會過不去，不過剛好全部頁面都統一由選取product來運作
// 而且這有個優點一頁選好後其他頁可以沿用
const routes = [
  {
    path: '/',
    name: 'Home',
    component: AboutR
  },
  {
    path: '/about/:product?',
    name: 'About',
    component: AboutR
  },
  {
    path: '/platform/:product?',
    name: 'Platform',
    component: PlatformR
  },
  {
    path: '/term-blockchain/:product?',
    name: 'TermAndBlockchain',
    component: TermAndBlockchain
  },
  {
    path: '/term/:product?',
    name: 'Term',
    component: TermR
  }
]

const router = new VueRouter({
  routes
})

export default router
